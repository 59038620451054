if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initLogin);
} else {
    initLogin();
}

function updateDatalayerReader(readerId, isLoggedIn) {
    window.dataLayer.push({
        "reader": {
            "readerId": readerId,
            "isLoggedIn": isLoggedIn
        }
    });

    window.nuglif.analytics.state.setUserId(readerId);
}


function onLoggedIn(user) {
    const {nuglif} = window;

    const userAccountWidgets = document.querySelectorAll(".userAccountWidget");
    if (userAccountWidgets && userAccountWidgets.length > 0) {
        userAccountWidgets.forEach(widget => {
            widget.classList.add("userAccountWidget--loggedIn");
            if (!user.emailVerified) {
                widget.classList.add("userAccountWidget--notVerified");
            }
        });
    }

    const userAccountEmails = document.querySelectorAll(".userAccountWidget__email");
    if (userAccountEmails && userAccountEmails.length > 0) {
        userAccountEmails.forEach(element => element.innerHTML = user.email);
    }

    nuglif.ngApp.user.loggedInUUID = user.uid;
    updateDatalayerReader(user.uid, true);
}

function onLoggedOut() {
    const {nuglif} = window;

    const userAccountWidgets = document.querySelectorAll(".userAccountWidget");
    if (userAccountWidgets && userAccountWidgets.length > 0) {
        userAccountWidgets.forEach(widget => {
            widget.classList.remove("userAccountWidget--loggedIn");
            widget.classList.remove("userAccountWidget--notVerified");
        });
        document.querySelectorAll(".mainNav__userAccountItem").forEach(element => element.classList.remove("mainNav__userAccountItem--state-open"));
    }

    const userAccountEmails = document.querySelectorAll(".userAccountWidget__email");
    if (userAccountEmails && userAccountEmails.length > 0) {
        userAccountEmails.forEach(element => element.innerHTML = "");
    }

    nuglif.ngApp.user.loggedInUUID = null;
    updateDatalayerReader(null, false);
}

async function initLogin() {
    const {lpauth, nuglif} = window;

    await lpauth.init({
        firebase: window.FIREBASE_CONFIG,
        api: {
            authService: {
                baseUrl: window.AUTHENTICATION_SERVICE_BASE_URL
            }
        },
        emitEvent: (name, payload = {}) => window.nuglif.analytics?.pubSub.publish(name, ...payload)
    });

    await lpauth.renderLogin(document.getElementById("login-root"));

    lpauth.onAuthStateChanged(function(event) {
        if (event && event.user) {
            onLoggedIn(event.user);
        } else {
            onLoggedOut();
        }
    });

    lpauth.onAuthReady(function() {
        //TODO: Build an object that manages integration with Google Tag manager
        //      this object should be responsible to handle these calls.
        //      The problem here is google tag manager integration is spread out in many files.
        if (lpauth.currentUser()) {
            nuglif.ngApp.user.loggedInUUID = lpauth.currentUser().uid;
            updateDatalayerReader(lpauth.currentUser().uid, lpauth.isLoggedIn());
        } else {
            updateDatalayerReader(null, false);
        }
        window.nuglif.analytics.pubSub.publish("authReady");
    });

    lpauth.onLoginSuccess(function() {
        let url = window.location.href;

        if (url.match(/logout/gi) || url.match(/reinitialiser-mot-de-passe/gi)) {
            window.location = "/mon-compte";
        }
    });

    lpauth.onLogout(function() {
        let url = window.location.href;

        if (url.match(/mon-compte/gi)) {
            window.location = "/logout";
        }
    });

    lpauth.onLoginOpened(function() {
        window.nuglif.analytics.pubSub.publish("loginOpen");
    });

    lpauth.onLoginSuccess(function() {
        window.nuglif.analytics.pubSub.publish("loginClose", {result: "success"});
    });

    lpauth.onLoginCancelled(function() {
        window.nuglif.analytics.pubSub.publish("loginClose", {result: "cancel"});
    });

    lpauth.onNewAccountLoginSuccess(function() {
        let url = window.location.href;

        window.nuglif.analytics.pubSub.publish("ghostAccountTerminated", {mode: "email"});
        if (url.match(/reinitialiser-mot-de-passe/gi)) {
            const urlParams = new URLSearchParams(window.location.search);
            const continueUrl = urlParams.get("continueUrl");
            window.location = continueUrl ?? "/mon-compte";
        }
    });

    lpauth.onNewAccountLoginCancelled(function() {
        let url = window.location.href;
        window.nuglif.analytics.pubSub.publish("newAccountLoginClose", {
            result: "cancel"
        });


        if (url.match(/reinitialiser-mot-de-passe/gi)) {
            window.location = lpauth.isLoggedIn ? "/mon-compte" : "/";
        }
    });
}
